import React, { useState, useRef } from "react"
import { Link, graphql } from "gatsby"
import { navigate } from 'gatsby-link'
import SimpleReactValidator from "simple-react-validator"

import Meta from "../../components/addon/meta"
import Layout from "../../components/layout/layout"
import APIButton from "../../components/addon/button/apiButton"

import useScript from "../../service/useScript"
import { acceptOnlyNumbers } from "../../service/helper"
import PATIENT_API from "../../api/refer-patient"
import toast from "react-hot-toast"

const PayIPDBillInfo = (props) => {
    const metaTags = props?.data.allNodeTemplatePages.edges[0]?.node?.metatag ? props?.data.allNodeTemplatePages.edges[0].node.metatag : []
    const [admissionNo, setAdmissionNo] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [data, setData] = useState(null)
    // {
    //     "adm_no": "1316929",
    //     "hh_no": "556611",
    //     "totalbillamount": "109450",
    //     "totalcharity": "0",
    //     "totaldepositamount": "100",
    //     "totalreceivable": "109350",
    //     "patfirstname": "TIKAM",
    //     "patlastname": "SACHDEV",
    //     "outstanding": "0"
    // }
  
    const [, forceUpdateForRegister] = useState();
    useScript("/assets/js/login-register.js");
    const [validator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))

    const resetData = () => {
        setSuccess(false)
        setAdmissionNo('')
    }

    const getPatientData = () => {
        setLoading(true)
        PATIENT_API.getPatientByAdmissionNo(admissionNo)
        .then(res => {
            if(res?.Data) {
                setData(res?.Data)
            }
            setSuccess(true)
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            toast.error(err?.response?.data ? err?.response?.data : 'Something went wrong')
            setSuccess(false)
            setLoading(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const result = validator.current.allValid()
        if(!result) {
            validator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if(result && admissionNo?.length) {
            getPatientData()
        }
    }

    const goToDetails = () => {
        navigate(`/pay-ipd-bill/details`, { state: { user: data }})
    }

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: ['/assets/css/services.css', '/assets/css/accessibility.css']
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                {success 
                ? <section className="confirmation_section section-bg section-py">
                    <div className="container">
                        <div className="white_rounded_box">
                            <div className="tankyou_div">
                                <h2 className="section-heading">Confirmation</h2>
                                <div className="check_mark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                        <g data-name="Group 2657" transform="translate(0)">
                                            <path data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470"></path>
                                            <g data-name="Group 2463" transform="translate(14.719 18.535)">
                                                <line data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                                <line data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="thankyou_content">
                                    <p>Dear <span>{data?.patfirstname +' '+data?.patlastname},</span> HH no. {data?.hh_no} </p>
                                    <p>Kindly confirm your name and HH No./EX No.</p>
                                    <p className="mt-4"> <span>If correct</span> - Please proceed</p>
                                    <p> <span>If incorrect</span> – Please go back and enter your correct details.</p>
                                </div>
                                <div className="btn_wrap btn_group">
                                    <button className="btn btn-primary btn-primary-solid back_btn" onClick={resetData}>Back</button>
                                    <a className="btn btn-primary proceed_btn" onClick={goToDetails}>Proceed</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                : <section className="enter_details_section section-bg section-py">
                    <div className="container">
                        <h2 className="section-heading">Enter your details</h2>
                        <div className="white_rounded_box">
                            <div className="form_wrap">
                                <p className="note">
                                Note: This facility is available for registered patients only.
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group -animated">   
                                            <label>Enter Admission No <span className="mandatory">*</span></label>
                                            <input className="form-control" type="text" value={admissionNo} onChange={(e) => setAdmissionNo(acceptOnlyNumbers(e?.target?.value))} name="admissionNo" />
                                            {validator.current.message('admissionNo', admissionNo, 'required')}                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="btn_group_left">
                                    <Link to="/online-services" className="btn btn-primary btn-primary-solid back_btn">Back</Link>
                                    <APIButton title={`Continue`} 
                                        loading={loading} 
                                        disabled={loading} 
                                        onBtnClick={(e) => handleSubmit(e)}  
                                        className={`btn btn-primary continue_btn`} 
                                    />
                                    {/* <button className="btn btn-primary continue_btn">Continue</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            </main>
        </Layout>
    )
}

export const query = graphql`
query payIpdBillQuery {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/pay-ipd-bill$/"}}}) {
        edges {
          node {
            id
            title
            path {
              alias
            }
            metatag {
                attributes {
                    content
                    href
                    name
                    property
                    rel
                }
            }
          }
        }
    }
  }  
`

export default PayIPDBillInfo